<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />
          <TemplateFiveList
            :type="'radio'"
            :list-data="listDataFive"
            :selectValue="listSelectFive"
            @change="listChangeFive"
          ></TemplateFiveList>
          <TemplateTreeList
            :type="'check'"
            :list-data="listData"
            :selectValue="listSelect"
            @change="listChange"
          ></TemplateTreeList>
          <div
            v-if="compute === true"
            style="margin: 16px;display: flex;justify-content: center;"
          >
            <van-button
              class="formula-template-button"
              block
              type="info"
              @click="submit"
              style="width:90px"
              >提交</van-button
            >
          </div>
        </div>
      </div>
      <CalculationResults
        :countText="countText"
        :countTextList="countTextList"
        v-if="show"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="应激指数"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateTreeList from "@/components/Template3/TemplateTreeList";
import CalculationResults from "@/components/CalculationResults";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";

export default {
  name: "medicalSearch",
  data() {
    return {
      show: false,
      compute: true,
      listSelect: [],
      listSelectFive: [],
      listDataFive: [
        {
          title: "性别",
          columns: ["男", "女"],
        },
      ],
      listData: [
        {
          title: "既往脑卒中或短暂性脑缺血发作TIA病史",
        },
        {
          title: "年龄大于75岁",
        },
        {
          title: "既往有高血压病史",
        },
        {
          title: "收缩压>160mmHg",
        },
        {
          title: "既往有糖尿病病史",
        },
        {
          title: "无任何上述危险因素",
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["低危", "中危", "高危"],
      nianling: "",
      xingbie: null,
      tableData: {},
      countText: "",
      countTextList: [],
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateTreeList,
    CalculationResults,
    TemplateFiveList,
  },
  async created() {
    await this.test();
    await this.getTableData();
  },
  // watch: {
  //   listSelect: {
  //     immediate: true,
  //     handler: function() {
  //       const flag = this.listSelect.find(
  //         (item) => item == "无任何上述危险因素"
  //       );
  //       if (flag) {
  //         this.listSelect=[]
  //          this.listSelect = ["无任何上述危险因素"];

  //       }
  //     },
  //   },
  // },

  methods: {
    listChangeFive(result) {
      this.listSelectFive = result;
    },
    listChange(result) {
      this.show = false;
      this.listSelect = result;
      if (this.listSelect[this.listSelect.length - 1] == "无任何上述危险因素") {
        this.listSelect = this.listSelect.filter(
          (item) => item == "无任何上述危险因素"
        );
      } else {
        this.listSelect = this.listSelect.filter(
          (item) => item !== "无任何上述危险因素"
        );
      }
    },
    coumputTetxt() {
      console.log(this.listSelect);
      console.log(this.listSelectFive);
      this.show = true;
      if (
        this.listSelect.length == 1 &&
        this.listSelect[0] == "无任何上述危险因素"
      ) {
        this.countText = "低危患者（脑卒中风险1.5%）";
        return;
      }
      const flag = this.listSelect.find(
        (item) =>
          item == "既往脑卒中或短暂性脑缺血发作TIA病史" ||
          item == "收缩压>160mmHg"
      );
      if (flag) {
        this.countText = `高危患者（脑卒中风险5.7%）`;
        return;
      }
      const flag2 = this.listSelect.find((item) => item == "年龄大于75岁");
      if (flag2) {
        if (
          this.listSelect.find((item) => item == "既往有高血压病史") ||
          this.listSelectFive[0].value[0] == "女"
        ) {
          this.countText = `高危患者（脑卒中风险5.7%）`;
          return;
        } else {
          this.countText = `中危患者（脑卒中风险1.7%～5.2%）`;
          return;
        }
      } else {
        this.countText = `中危患者（脑卒中风险1.7%～5.2%）`;
      }
      this.result = this.countText;
    },
    // if(this.listSelectFive[0].value=='女' || this.listSelect.find(item=>item=='既往有高血压病史')

    // }
    // let fenshu = 0;
    // let fenzhi = [];
    // fenzhi = this.listSelect
    //   .map((item) => {
    //     const list = this.listData.filter((row) => row.title === item);
    //     return list && list.length > 0 ? list[0].value : null;
    //   })
    //   .filter((title) => title !== null);
    // for (let i = 0; i < fenzhi.length; i++) {
    //   fenshu += fenzhi[i];
    // }
    // if (fenshu <= 0) {
    //   this.countText = fenshu + "分 " + "低危";
    // } else if (fenshu >= 1 && fenshu <= 2) {
    //   this.countText = fenshu + "分 " + "中危";
    // } else if (fenshu >= 3) {
    //   this.countText = fenshu + "分 " + "高危";
    // }

    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "ACTWGIMN",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.listSelect = res.data.content.value.listSelect;
          this.listSelectFive = res.data.content.value.listSelectFive;

          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.coumputTetxt();
        }
      }
    },
    async submit() {
      if (!this.listSelect.length || this.listSelect.length === 0) {
        Toast.fail("请至少选择除性别外的一项！");
        return;
      }
      this.coumputTetxt();

      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: "0",
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: {
            listSelect: this.listSelect,
            listSelectFive: this.listSelectFive,
          },
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          DisplayResults: this.countText,
        },
      };
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    // async onSubmit() {
    //   this.coumputTetxt();
    //   const data = {
    //     id:
    //       this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
    //         ? this.$router.currentRoute.query.logId
    //         : null,
    //     patientId: this.patientId,
    //     columnCode: this.tableData.columnCode,
    //     formulaCode: this.tableData.code,
    //     channel: this.$router.currentRoute.query.channel,
    //     location: this.$router.currentRoute.query.location,
    //     content: {
    //       shengao: { value: this.shengao, unit: "cm" },
    //       tizhong: { value: this.tizhong, unit: "kg" },
    //       nianling: { value: this.nianling, unit: "岁" },
    //       xingbie: { value: this.xingbie == 1 ? "男" : "女" },
    //       // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
    //     },
    //     result: {},
    //   };
    //   if (this.xingbie == 1) {
    //     data.result.nanxing = { value: this.result, unit: "kcal" };
    //   } else {
    //     data.result.nvxing = { value: this.result, unit: "kcal" };
    //   }
    //   const res = await postFormula(data);
    //   if (res.code === 0) {
    //     Toast.success("提交成功");
    //   } else {
    //     Toast.fail(res.message);
    //   }
    // },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
